
<template>
<v-container fluid class="main-container">
  <div class="main-container-inner">
    <p>Fashion<br>Data<br>Lab</p>
    <p class="line_text">We create the future of fashion.</p>
  </div>
  </v-container>
</template>

<script>
  export default {
    name: 'home-main',
    data: () => ({
      drawer: false
  })
  }
</script>

<style>
  .main-container {
    background-image: url("./assets/background.jpg");
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
  }
  .main-container-inner {
    position:relative;
    top: 10%;
    right: -30%;
  }
  p {
    color: white;
    font-size: 70pt;
    font-weight: 900;
    font-family: Helvetica, Arial, sans-serif;
  }
  .line_text {
    position:relative;
    top: 40pt;
    right: -3%;
    font-size: 32pt;
  }
  @media screen and (max-width:480px) {
    .main-container-inner {
      width: 70%;
    }
    p {
      font-size: 40pt;
      width: 70%;
    }
    .line_text {
      font-size: 12pt;
      right: 8%;
    }
    .main-container {
    background-size: cover;
    width: 100%;
    height: 100%;
    }
  }
</style>