
<template>
  <v-container fluid class="product-help-main-container">
    <div class="product-help-container-inner">
      <p class="help-page-title">開発テストアプリインストール手順</p>
      <p class="help-title">1. アンケートフォームへの回答</p>
      <p class="help-message">開発テストアプリの紹介者から共有されたアンケートフォームから必要事項を入力してください。</p>
      <p class="help-title">2. 案内メールの受信</p>
      <p class="help-message">8月16日頃、アンケートフォームに登録いただいたメールアドレスにTestFlightから「Fashion Data Lab inc. has invited you to test fype.」というタイトルのメールが送信されます。</p>
      <img class="help-image" src="./assets/fype_help_mail.png">
      <p class="help-message">このメールで「View in TestFlight」をクリックすると、ウェブブラウザが立ち上がり、下記のような画面に遷移します。</p>
      <img class="help-image" src="./assets/fype_help_mail_content.png">
      <p class="help-message">「３」に記載されているコードを利用するのでメモまたはスクリーンショットを保存しておきます。</p>
      <p class="help-title">3. TestFlightアプリのインストール</p>
      <p class="help-message">iPhoneにTestFlightアプリをインストールします。いずれかの方法でインストールすることができます。</p>
      <p class="help-message">・先ほどのウェブブラウザをiPhoneで開き、「TestFlight from App Store」をクリックするとAppStoreの該当のページが立ち上がるのでインストールします。</p>
      <p class="help-message">・iPhoneのApp Storeで「TestFlight」と検索してTestFlightアプリをiPhoneにインストールします。</p>
      <img class="help-image-small" src="./assets/fype_help_store.png">
      <p class="help-title">4. TestFlightアプリの起動</p>
      <p class="help-message">初めてTestFlightアプリを起動すると利用規約同意画面が表示されるため、問題なければ「同意する」をタップします。</p>
      <img class="help-image-small" src="./assets/fype_help_testflight_agree.jpeg">
      <p class="help-title">5. TestFlightへの招待コード入力</p>
      <p class="help-message">インストールしたTestFlightアプリの「コードを使う」ボタンをタップすると、招待コードの入力を求められるためメモしておいたコードを入力します。</p>
      <img class="help-image-small" src="./assets/fype_help_testflight.png">
      <p class="help-title">6. TestFlightからテストアプリのインストール</p>
      <p class="help-message">招待コードを入力するとアプリのインストールが可能になるので、「インストール」ボタンをタップしてアプリをインストールします。</p>
      <img class="help-image-small" src="./assets/fype_help_testflight2.png">
      <p class="help-message">以下のような画面が表示された場合は「次へ」をタップします。</p>
      <img class="help-image-small" src="./assets/fype_help_testflight_confirm.jpeg">
      <div class="last-content">
        <p class="help-title">7. fypeへのログイン</p>
        <p class="help-message-last">fypeを起動後、「新規アカウント作成はこちら」をタップし任意のメールアドレスでアカウントを作成してfypeをご利用ください。</p>
        <img class="help-image-small" src="./assets/fype_help_login.png">
      </div>
    </div>
    </v-container>
  </template>

  <script>
    export default {
      name: 'home-main',
      data: () => ({
        drawer: false
    })
    }
  </script>

  <style>
    .product-help-main-container{
      padding-top: 2%;
      background-color: #EEEEEE;
      width: 100%;
    }
    .product-help-container-inner {
      position:relative;
      align-content: center;
      top: 5%;
      padding-left: 3%;
      max-width: 85%;
      overflow-wrap: break-word;
      word-break: break-word;
      word-break: break-all;
    }
    .help-page-title {
      padding-top: 1.5%;
      color: black;
      font-size: 26pt;
      font-weight: 700;
    }
    .help-title {
      padding-top: 1.5%;
      color: black;
      font-size: 24pt;
      font-weight: 700;
    }
    .help-message {
      padding-left: 1%;
      color: black;
      font-size: 16pt;
      font-weight: 400;
    }
    .help-image {
      padding: 1%;
      width: 40%;
    }
    .help-image-small {
      padding: 1%;
      width: 20%;
    }
    .help-message-last {
      padding-top: 3%;
      padding-left: 1%;
      color: black;
      font-size: 16pt;
      font-weight: 400;
    }
    .last-content {
      padding-bottom: 15%;
    }
    @media screen and (max-width:480px) {
      .product-help-container-inner {
        position:relative;
        align-content: center;
        top: 5%;
        max-width: 100%;
        overflow-wrap: break-word;
        word-break: break-word;
        word-break: break-all;
      }
      .help-page-title {
        padding-right: 5%;
        width: 100%;
        padding-top: 1.5%;
        color: black;
        font-size: 18pt;
        font-weight: 700;
      }
      .help-title {
        padding-right: 5%;
        width: 100%;
        padding-top: 3%;
        color: black;
        font-size: 16pt;
        font-weight: 700;
      }
      .help-message {
        padding-right: 5%;
        width: 100%;
        padding-left: 1%;
        color: black;
        font-size: 12pt;
        font-weight: 400;
      }
      .help-image {
        padding: 1%;
        width: 80%;
      }
      .help-image-small {
        padding: 1%;
        width: 50%;
      }
      .help-message-last {
        padding-top: 0%;
        padding-left: 1%;
        color: black;
        font-size: 12pt;
        font-weight: 400;
      }
    }
  </style>