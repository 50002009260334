
<template>
  <v-container fluid class="about-main-container">
    <div class="about-container-inner">
      <p class="about-title">Fashion Data Lab, Inc.</p>
      <hr>
      <table class="about-table" style="border:none;">
        <tbody style="border:none;">
          <tr style="border:none;">
            <td class="td-title" style="border:none;">代表者</td>
            <td class="td-content" style="border:none;">青木 智広</td>
          </tr>
          <tr style="border:none;">
            <td class="td-title" style="border:none;">設立</td>
            <td class="td-content" style="border:none;">2020年9月8日</td>
          </tr>
          <tr style="border:none;">
            <td class="td-title" style="border:none;">主な事業内容</td>
            <td class="td-content" style="border:none;">ファッションにかかるアプリケーションソフトの企画開発、データ分析</td>
          </tr>
          <tr style="border:none;">
            <td class="td-title" style="border:none;">所在地</td>
            <td class="td-content" style="border:none;">東京都渋谷区神宮前6-23-4 桑野ビル2F</td>
          </tr>
        </tbody>
      </table>
    </div>
    </v-container>
  </template>

  <script>
    export default {
      name: 'home-main',
      data: () => ({
        drawer: false
    })
    }
  </script>

  <style>
    .about-main-container {
      background-color: white;
      width: 100%;
      height: 100%;
    }
    .about-container-inner {
      position:relative;
      top: 5%;
      right: -8%;
    }
    p.about-title {
      color: black;
      font-size: 24pt;
      font-weight: 700;
      font-family: Helvetica, Arial, sans-serif;
    }
    hr.hr-about {
      border: 0.5px solid black;
      width: 700px;
    }
    table.about-table {
      position:relative;
      top: 30pt;
    }
    td.td-title {
      font-weight: 700;
    }
    td.td-content {
      padding: 10px;
      padding-left: 25px;
      padding-right: 80px;
    }

    @media screen and (max-width:480px) {
      .about-container-inner {
        width: 92%;
      }
      table {
        width: 100%;
      }
      td.td-title {
        width: 110pt;
      }
      td.td-content {
        padding: 10px;
        padding-right: 10px;
      }
      hr {
        width: 85%;
      }
    }
  </style>