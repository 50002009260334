
<template>
  <v-container fluid class="product-main-container">
    <div class="product-container-inner">
      <p class="product-title">アプリタイトル一覧</p>
        <div class="app-list">
          <div class="app-fype">
            <table style="border:none;">
              <tbody style="border:none;">
                <tr style="border:none;">
                  <td class="product-td" style="border:none;" valign="top">
                    <img class="app-icon-fype" src="./assets/fype_logo.png">
                  </td>
                  <td class="td-product-content" style="border:none;">
                    <p class="app-title">fype</p>
                    <p class="app-message">ファッションをもっと楽しく。<br>ファッションセンス可視化アプリ「fype」</p>
                    <p class="app-link-message">開発テストに参加いただいた方は<a href="/product/fype-help">こちら</a>から参加手順をお確かめください。</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        <hr>
      </div>
    </div>
    </v-container>
  </template>

  <script>
    export default {
      name: 'home-main',
      data: () => ({
        drawer: false
    })
    }
  </script>

  <style>
    .product-main-container {
      background-color: white;
      width: 100%;
      height: 100%;
    }
    .product-container-inner {
      position:relative;
      top: 5%;
      left: 5%;
    }
    .product-title {
      color: black;
      font-size: 24pt;
      font-weight: 700;
    }
    .app-list {
      margin-top: 4%;
    }
    .app-fype {
      margin-bottom: 2%;
    }
    .app-icon-fype {
      border-radius: 30px;
      width: 150px;
      height: 150px;
    }
    p.app-title {
      position:relative;
      text-align: left;
      color: black;
      font-size: 26pt;
      font-weight: 700;
      font-family: Helvetica, Arial, sans-serif;
    }
    p.app-message {
      position:relative;
      text-align: left;
      color: black;
      font-size: 14pt;
      font-weight: 700;
      font-family: Helvetica, Arial, sans-serif;
    }
    p.app-link-message {
      position:relative;
      text-align: left;
      color: black;
      font-size: 12pt;
      font-weight: 400;
      font-family: Helvetica, Arial, sans-serif;
    }
    td.td-product-content {
      padding: 10px;
      padding-left: 5%;
      padding-right: 80px;
    }
    @media screen and (max-width:480px) {
      .product-title {
        color: black;
        font-size: 18pt;
        font-weight: 700;
      }
      .app-icon-fype {
        border-radius: 10px;
        width: 50px;
        height: 50px;
      }
      td.product-td {
        padding-top: 5px;
      }
      p.app-title {
        position:relative;
        text-align: left;
        color: black;
        font-size: 18pt;
        font-weight: 700;
        font-family: Helvetica, Arial, sans-serif;
      }
      p.app-message {
        width: 100%;
        padding-right: 15%;
        position:relative;
        text-align: left;
        color: black;
        font-size: 12pt;
        font-weight: 700;
        font-family: Helvetica, Arial, sans-serif;
      }
      p.app-link-message {
        width: 100%;
        padding-right: 15%;
        position:relative;
        text-align: left;
        color: black;
        font-size: 10pt;
        font-weight: 400;
        font-family: Helvetica, Arial, sans-serif;
      }
      td.td-product-content {
        padding-left: 5%;
        padding-right: 0%;
        width: 100%;
      }
    }

  </style>