<template>
  <v-app>
    <!-- サイドメニュー -->
    <v-navigation-drawer app clipped v-model="drawer" dark color="black">
      <v-list nav>
        <v-list-item v-for="menu in menus" :key="menu.title" :to="menu.url">
          <v-list-item-content>
            <v-list-item-title>{{ menu.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!-- ヘッダー -->
    <v-app-bar app clippedLeft flat dark color="black">
      <v-app-bar-nav-icon @click.stop="drawer=!drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>Fashion Data Lab</v-toolbar-title>
    </v-app-bar>

    <!-- メイン -->
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>
<script>
  export default {
    name: 'home-main',
    data: () => ({
    drawer: false,
    menus: [
      { title: 'Home', url: '/' },
      { title: 'Product', url: '/product' },
      { title: 'About', url: '/about' }
    ]
  })
  }
</script>

<style>
  .main-container {
    background-image: url("./assets/background.jpg");
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
  }
  .main-container-inner {
    position:relative;
    top: 80px;
    right: -450px;
  }
</style>