import Vue from 'vue'
import Router from 'vue-router'
import Home from './Home'
import Product from './Product'
import About from './About'
import AppFypeHelp from './AppFypeHelp'
//
// 他のコンポーネントは省略
//

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/product',
      name: 'product',
      component: Product
    },
    {
      path: '/about',
      name: 'about',
      component: About
    },
    {
      path: '/product/fype-help',
      name: 'fype-help',
      component: AppFypeHelp
    }
  ]
})